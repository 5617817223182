import './side-panel';

const $programsForm = document.querySelector('.side-panel .side-panel-programs');
const $nameInput = document.querySelector('.side-panel .side-panel-programs .first-name-input');
const $lastNameInput = document.querySelector('.side-panel .side-panel-programs .last-name-input');
const $emailInput = document.querySelector('.side-panel .side-panel-programs .courriel-input');
const $phoneInput = document.querySelector('.side-panel .side-panel-programs .phone-input');
const $productsDropdown = document.querySelector('.side-panel .side-panel-programs .products-select-input');
const $programDatePicker = document.querySelector('.side-panel .side-panel-programs .programs-date-input');
const $btnElectricSubmit = document.querySelector('.side-panel .side-panel-programs .btn-submit-programs');

if ($programsForm) {
    // Enabled submit button if first name, last name and message are not empty
    const updateSubmitButton = () => {
        if ($nameInput.value.trim() !== '' && $lastNameInput.value.trim() !== '' && $emailInput.value.trim() !== '' && $phoneInput.value.trim() !== '' && $productsDropdown.value !== '' && $programDatePicker.value !== '') {
            $btnElectricSubmit.removeAttribute('disabled');
        } else {
            $btnElectricSubmit.setAttribute('disabled', 'true');
        }
    };

    const inputs = [$nameInput, $lastNameInput, $emailInput, $phoneInput, $productsDropdown, $programDatePicker];
    inputs.forEach((input) => {
        input.addEventListener('input', updateSubmitButton);
    });
    $productsDropdown.addEventListener('change', updateSubmitButton);
}
